import React, { Fragment, useState } from "react";
import {
  withRouter,
  Row,
  Col,
  toast,
  Axios,
  CircularProgress,
  TEXTFIELD,
  CKEditor,
  SUBMIT,
  ApiUrl,
  checkValidations,
  checkError,
  Link,
  FILEUPLOAD,
} from "../../../../../../components";

const { API_UPLOAD_FOLDER, API_GET_TEMPLATE, API_EDIT_TEMPLATE } = ApiUrl;

class AddEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.editId,
      templateId: this.props.templateId,
      // id: 33,
      name: "",
      description: "",
      file: [],
      fileData: "",
      editid: "",
      errors: {
        name: "",
        description: "",
        file: {},
      },
      process: this.props.role,
      loading: false,
      uploaded_date: "",
    };
  }

  async componentDidMount() {
    console.log(this.props.templateId, "stateid");
    const { id, process } = this.state;
    this.setState({ loading: true });

    if (process == "edit" && id) {
      Axios.get(API_GET_TEMPLATE + id)
        .then((response) => {
          var data = response.data.data;
          this.setState({
            name: data.name,
            description: data.description,
            template: data.template,
            editid: data.uploaded_for.hash_id,
            uploaded_date: data.uploaded_date,
            loading: false,
          });
        })
        .catch((error) => {
          console.log("error");
        });
    }
    this.setState({ loading: false });
  }

  handleChange = (event) => {
    console.log(event, "asdasdasdasd");
    event.preventDefault();
    const { name, value, validations, email } = event.target;
    let errors = this.state.errors;
    let finalVal = value;

    switch (name) {
      case "file":
        errors.file = value.length < 1 ? "Required" : "";
        break;

      case "respondent":
        errors.respondent = value.length < 1 ? "Required" : "";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const {
      process,
      id,
      name,
      description,
      file,
      templateId,
      uploaded_date,
      fileData,
    } = this.state;

    console.log(file, fileData, "kjhfsdfsdd");

    this.setState({ loading: true });

    const data = {
      name: name,
      description: description,
      template: file,
      uploaded_date: uploaded_date,
    };

    // check validations
    let valid = checkValidations(data);
    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false });
      return false;
    }

    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    console.log(data, valid.data);

    let url = API_UPLOAD_FOLDER;
    if (process == "edit") {
      url = API_EDIT_TEMPLATE;
    }

    Axios.Post(url, formData)
      .then((res) => {
        let response = res.data;
        console.log(response, response.error, response.success, "true123");
        // toast.success(response.message);

        if (response.success === true) {
          toast.success(response.message);
        } else {
          response.error.template.length >= 0

            toast.error(response.error.template[0])
            //  response.template.map((er) => {
            //   console.log(er,"erererer")
            //     toast.error(er);
            //   })

            // response.error &&  toast.error(response.error);
        }
      })
      .catch((error) => {
        toast.error(error);
      });

    this.setState({ loading: false });
  };
  // onChange(evt) {
  //   const newAnswer = evt.editor.getData();
  //   this.setState({
  //     answer: newAnswer,
  //   });
  // }

  handleFile1 = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let files = event.target.files; // Get the FileList object
    let newErr = { ...errors, file: "" };
    console.log(files, "files");

    let fileData = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryString = e.target.result;
        fileData.push(binaryString);
        if (fileData.length === files.length) {
          // All files have been read
          this.setState(
            {
              files: files, // Store the FileList object in the state
              fileData: fileData, // Store binary data of files in the state
              errors: newErr,
            },
            () => {
              let err = checkError(newErr);
              this.setState({ disabled: err.disabled });
            }
          );
        }
      };
      reader.readAsBinaryString(files[i]); // Read each file as binary string
    }
  };

  handleFile = (event) => {
    event.preventDefault();
    const { errors } = this.state;
    let file = event.target.files[0];
    let newErr = { ...errors, file: "" };
    console.log(file, "filefile");

    this.setState(
      {
        file: file,
        errors: newErr,
      },
      () => {
        let err = checkError(newErr);
        this.setState({ disabled: err.disabled });
      }
    );
    this.setState({ fileName: file.name });
  };

  render() {
    const {
      loading,
      name,
      description,
      template,
      fileName,
      errors,
      templateId,
      uploaded_date,
    } = this.state;

    var disabled = !name || !description ? true : "";

    return (
      <Fragment>
        {loading && (
          <div className="text-center w-100">
            <CircularProgress />
          </div>
        )}
        {/* <Link
          to={`/app/template/list/${templateId}`}
          style={{
            color: "grey",
            fontSize: "32px",
          }}
        >
          <i
            class="zmdi zmdi-arrow-left"
            style={{ fontSize: "22px", marginTop: "2px" }}
          ></i>
        </Link> */}
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.onFormSubmit.bind(this)}
          style={{ padding: "20px" }}
        >
          <Row>

            <Col md="4">
              <TEXTFIELD
                label="Name"
                value={name}
                name={"name"}
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>

            <Col md="8">
              <TEXTFIELD
                label="Description"
                value={description}
                name={"description"}
                onChange={this.handleChange}
                errors={errors}
              />
              {/* <CKEditor
                activeClass="mt-4 w-100"
                content={answer}
                events={{
                  change: this.onChange.bind(this),
                }}
              /> */}
            </Col>

            <Col md="4" xs="12" className="mt-2">
              <div class="file-upload-cus">
                <div class="file-select">
                  <div class="file-select-button" id="fileName">
                    Choose File
                  </div>
                  <div class="file-select-name" id="noFile">
                    {fileName ? fileName : "No file chosen..."}
                  </div>
                  <div class="importcus">
                    <input
                      type="file"
                      name={"file"}
                      id="chooseFile"
                      accept=".zip"
                      onChange={this.handleFile}
                    />
                  </div>
                </div>
              </div>
            </Col>

            {/* dharmani */}
            <Col md="4" xs="12" className="mt-2">       
              <TEXTFIELD
                label=""
                value={uploaded_date}
                name={"uploaded_date"}
                type="date" // Native date picker
                onChange={this.handleChange}
                errors={errors}
              />
            </Col>
            
            <Col md="2" className="mt-2">
              <SUBMIT title="Upload" disabled={disabled} />
            </Col>
          </Row>
        </form>
      </Fragment>
    );
  }
}

export default withRouter(AddEditForm);
// https://codesandbox.io/p/sandbox/react-select-all-checkbox-jbub2?file=%2Fsrc%2Findex.js%3A33%2C47