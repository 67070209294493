import React, { useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { ApiUrl, Axios } from "../../../components";
const {
  API_GET_USERS_APPROVALS,
  API_GET_NOTICES,
  API_GET_LOTS_LAWFIRM,
} = ApiUrl;
// import IntlMessages from "util/IntlMessages";
export default () => {
  const [list, setList] = useState([]);
  const [notices, setNotices] = useState([]);
  const [pending, setPending] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [mediationNotices, setMediationNotices] = useState([]);
  const [mediationList, setMediationList] = useState([]);
  const role = localStorage.getItem("role");
  const lawMediation = localStorage.getItem("lawMediation");

  const getTableRecords = async () => {
    Axios.Get(API_GET_USERS_APPROVALS + "respondent")
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setList(resData?.data || []);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };

  useEffect(() => {
    if (lawMediation === "false") {
      const getTableRecordsMediation = async () => {
        try {
          const response = await Axios.get(API_GET_USERS_APPROVALS + "respondent/mediation");
          const resData = response.data;
          if (response.status === 200) {
            // toast.success(resData.message);
            setMediationList(resData?.data || []);
          } else {
            toast.error(resData.error);
          }
        } catch (err) {
          console.log(err, "wwwwwwwww");
        }
      };

      getTableRecordsMediation();
    }
  }, [lawMediation]);


  // const getTableRecordsMediation = async () => {
  //   Axios.Get(API_GET_USERS_APPROVALS + "respondent/mediation")
  //     .then((response) => {
  //       const resData = response.data;
  //       if (response.status == 200) {
  //         // toast.success(resData.message);
  //         setMediationList(resData?.data || []);
  //       } else {
  //         toast.error(resData.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "wwwwwwwww");
  //     });
  // };

  const getNotices = async () => {
    Axios.Get(API_GET_NOTICES)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setNotices(resData?.data || []);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };
  const getPendingRecords = async () => {
    Axios.Get(API_GET_LOTS_LAWFIRM)
      .then((response) => {
        const resData = response.data;
        if (response.status == 200) {
          // toast.success(resData.message);
          setPending(resData?.data || []);
        } else {
          toast.error(resData.error);
        }
      })
      .catch((err) => {
        console.log(err, "wwwwwwwww");
      });
  };


  useEffect(() => {
    if (lawMediation === "false") {
      const getPendingRecords1 = async () => {
        try {
          const response = await Axios.get(API_GET_LOTS_LAWFIRM + "/mediation");
          // console.log(response, "pending data shhhhhhhh");
          const resData = response.data;
          if (response.status === 200) {
            // toast.success(resData.message);
            setPendingData(resData?.data || []);
          } else {
            toast.error(resData.error);
          }
        } catch (err) {
          console.log(err, "wwwwwwwww");
        }
      };
      getPendingRecords1();
    }
  }, [lawMediation]);


  // const getPendingRecords1 = async () => {
  //   Axios.Get(API_GET_LOTS_LAWFIRM + "/mediation")
  //     .then((response) => {
  //       console.log(response,"pending data shhhhhhhh")
  //       const resData = response.data;
  //       if (response.status == 200) {
  //         // toast.success(resData.message);
  //         setPendingData(resData?.data || []);
  //       } else {
  //         toast.error(resData.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "wwwwwwwww");
  //     });
  // };



  useEffect(() => {
    if (lawMediation === "false") {
      const getNotices1 = async () => {
        try {
          const response = await Axios.get(API_GET_NOTICES + "/mediation");
          // console.log(response, "notice mediation");
          const resData = response.data;
          if (response.status === 200) {
            // toast.success(resData.message);
            setMediationNotices(resData?.data || []);
          } else {
            toast.error(resData.error);
          }
        } catch (err) {
          console.log(err, "wwwwwwwww");
        }
      };
      getNotices1();
    }
  }, [lawMediation]);


  // const getNotices1 = async () => {
  //   Axios.Get(API_GET_NOTICES + "/mediation")
  //     .then((response) => {
  //       console.log(response, "notice mediation")
  //       const resData = response.data;
  //       if (response.status == 200) {
  //         // toast.success(resData.message);
  //         setMediationNotices(resData?.data || []);
  //       } else {
  //         toast.error(resData.error);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "wwwwwwwww");
  //     });
  // };



  useEffect(() => {
    getTableRecords();
    getNotices();
    getPendingRecords();
    // getPendingRecords1();
    // getNotices1();
    // getTableRecordsMediation();
  }, []);

  return (
    <div className="mt-4">
      {role == "Superadmin" ? (
        <ul className="nav-menu">
          {/* <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li> */}
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">User Management</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/list">
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">All </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/users/approved"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Approved </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/new">
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Pending </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">f
              <i className="zmdi zmdi-blur-circular" />
              <span className="nav-text">Pending Approvals</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbit-approval/list"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Arbitrator</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/claimant-approval/list"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Claimants</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/respondent-approval/list"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Respondent</span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li>
            <NavLink to="/app/role/list">
              <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
              <span className="nav-text">Roles </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/permission/list">
              <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
              <span className="nav-text">Permissions </span>
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/app/activity">
              <i className="zmdi zmdi-delicious zmdi-hc-fw" />
              <span className="nav-text">Activity Log </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/troubleshoot">
              <i className="zmdi zmdi-arrows zmdi-hc-fw" />
              <span className="nav-text">Troubleshoot</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Settings</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to="/app/profile/add">
                  {/* <i className="zmdi zmdi-settings zmdi-hc-fw" /> */}
                  <span className="nav-text">Profile Settings </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/notification">
                  {/* <i className="zmdi zmdi-notifications-active zmdi-hc-fw" /> */}
                  <span className="nav-text">Push Notification</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/sms"
                >
                  {/* <i class="zmdi zmdi-comment-text"></i> */}
                  <span className="nav-text">SMS Templates</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/email/add">
                  {/* <i className="zmdi zmdi-email-open" /> */}
                  <span className="nav-text">Email Settings </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/cache/add">
                  {/* <i className="zmdi zmdi-collection-speaker" /> */}
                  <span className="nav-text">System</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Mediation</span>
            </Button>

            <ul className="sub-menu">
              <li className="menu collapse-box">
                <Button href="javascript:void(0)" className="cbutton">
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">Case Manager</span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/admin/assign-case"
                    >
                      {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                      <span className="nav-text">Assign Case </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/admin/assign-manager"
                    >
                      {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                      <span className="nav-text">Assign Manager </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/admin/reassign-manager"
                    >
                      {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                      <span className="nav-text">Re-Assign </span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/app/admin/message-us">
              <i className="zmdi zmdi-email" />
              <span className="nav-text">Message Us</span>
            </NavLink>
          </li>
        </ul>
      ) : role == "Arbitrator" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/arbitrator/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/profile/add">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/change-password/add">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Change Password </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-dot-circle" />
              <span className="nav-text">Arbitration Cases</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/list"
                >
                  {/* <i className="zmdi zmdi-dot-circle" /> */}
                  <span className="nav-text">Bulk Cases</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/new"
                >
                  {/* <i className="zmdi zmdi-dot-circle" /> */}
                  <span className="nav-text">Single Cases </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/pending"
                >
                  <i className="zmdi zmdi-dot-circle" />
                  <span className="nav-text">Pending Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/disposed"
                >
                  <i className="zmdi zmdi-dot-circle" />
                  <span className="nav-text">Disposed Cases </span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/party/join-meeting">
              <i className="zmdi zmdi-videocam" />
              <span className="nav-text">Join Meeting</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/mediation-process/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Mediation </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/conciliation/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Conciliation </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/bookings/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "Mediator" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/mediator/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/profile/add">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Profile Settings</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/change-password/add">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Change Password</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-dot-circle" />
              <span className="nav-text">Mediator Cases</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/mediator/list"
                >
                  {/* <i className="zmdi zmdi-dot-circle" /> */}
                  <span className="nav-text">Bulk Cases</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/mediator/new"
                >
                  {/* <i className="zmdi zmdi-dot-circle" /> */}
                  <span className="nav-text">Single Cases </span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/pending"
                >
                  <i className="zmdi zmdi-dot-circle" />
                  <span className="nav-text">Pending Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/arbitrator/disposed"
                >
                  <i className="zmdi zmdi-dot-circle" />
                  <span className="nav-text">Disposed Cases </span>
                </NavLink>
              </li> */}
            </ul>
          </li>
          <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/party/join-meeting">
              <i className="zmdi zmdi-videocam" />
              <span className="nav-text">Join Meeting</span>
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/app/mediation-process/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Mediation </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/conciliation/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Conciliation </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/arbitrator/bookings/list">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li> */}
        </ul>
      ) : role == "Advocate" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/advocate/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/advocate/change-password">
              <i className="zmdi zmdi-edit" />
              <span className="nav-text">Change Password</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/advocate/join-meeting">
              <i className="zmdi zmdi-videocam" />
              <span className="nav-text">Join Meeting</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Arbitration</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/register-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/registered-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/all-respondant-cases/"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Respondent Cases</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Mediation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/mediation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/mediation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/mediation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Conciliation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/conciliation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/conciliation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/advocate/conciliation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/advocate/bookings">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/advocate/change-password">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "Mediator" ? (
        <ul className="nav-menu">
          {/* <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li> */}
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Arbitration Cases</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/list">
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">All Cases</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/users/approved"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Fresh Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/users/new">
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/app/profile/add">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "LawFirm" ? (
        lawMediation === "true" || lawMediation === null ? (
          <ul className="nav-menu">
            <li>
              <NavLink to="/app/lawfirm/dashboard/">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">Dashboard</span>
              </NavLink>
            </li>

            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                <span className="nav-text">Clients</span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/client/add"
                  >
                    {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                    <span className="nav-text">Add Client </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/client/list"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Clients List</span>
                  </NavLink>
                </li>
                {/* <li>
                <NavLink to="/app/template">
                  <i className="zmdi zmdi-assignment" />
                  <span className="nav-text">Templates </span>
                </NavLink>
              </li> */}
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-file-text" />
                <span className="nav-text">Approvals</span>
                <span className="approval-count ml-4">
                  {pending?.length + notices?.length}
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/lawfirm/pending-cases/"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Pending Cases</span>
                    <span className="approval-count">{pending?.length}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/lawfirm/notices/"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Notices</span>
                    <span className="approval-count">{notices?.length}</span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/app/lawfirm/users-approval/">
                <i className="zmdi zmdi-cloud-upload" />
                <span className="nav-text">User Approval</span>
                <span className="approval-count">{list?.length}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/lawfirm/documents">
                <i className="zmdi zmdi-collection-text" />
                <span className="nav-text">Bulk Document</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/app/lawfirm/excel">
                <i className="zmdi zmdi-cloud-upload" />
                <span className="nav-text">Upload Shipments</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/lawfirm/signature">
                <i className="zmdi zmdi-border-color" />
                <span className="nav-text">Sign Digitally</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/lawfirm/update-cases">
                <i className="zmdi zmdi-comment-edit" />
                <span className="nav-text">Update Cases</span>
              </NavLink>
            </li>

            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-settings zmdi-hc-fw" />
                <span className="nav-text">Settings</span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/app/lawfirm/profile">
                    <i className="zmdi zmdi-settings zmdi-hc-fw" />
                    <span className="nav-text">Profile Settings </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/app/lawfirm/general">
                    <i className="zmdi zmdi-settings zmdi-hc-fw" />
                    <span className="nav-text">General Settings </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/app/lawfirm/message-us">
                <i className="zmdi zmdi-email" />
                <span className="nav-text">Message Us</span>
              </NavLink>
            </li>
          </ul>
        ) : (
          <ul className="nav-menu">
            <li>
              <NavLink to="/app/mediation/dashboard/">
                <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                <span className="nav-text">Dashboard</span>
              </NavLink>
            </li>

            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                <span className="nav-text">Clients</span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/mediation-client/add"
                  >
                    {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                    <span className="nav-text">Add Client </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/mediation-client/list"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Clients List</span>
                  </NavLink>
                </li>
                {/* <li>
                <NavLink to="/app/template">
                  <i className="zmdi zmdi-assignment" />
                  <span className="nav-text">Templates </span>
                </NavLink>
              </li> */}
              </ul>
            </li>
            <li className="menu collapse-box">
              <Button href="javascript:void(0)" className="cbutton">
                <i className="zmdi zmdi-file-text" />
                <span className="nav-text">Approvals</span>
                <span className="approval-count ml-4">
                  {pendingData?.length + mediationNotices?.length}
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/mediation/pending-cases/"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Pending Cases</span>
                    <span className="approval-count">{pendingData?.length}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon cbutton"
                    to="/app/mediation/notices/"
                  >
                    {/* <i className="zmdi zmdi-file-text" /> */}
                    <span className="nav-text">Notices</span>
                    <span className="approval-count">{mediationNotices?.length}</span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink to="/app/mediation/users-approval/">
                <i className="zmdi zmdi-cloud-upload" />
                <span className="nav-text">User Approval</span>
                <span className="approval-count">{mediationList?.length}</span>
              </NavLink>
            </li>


            <li>
              <NavLink to="/app/mediation/documents">
                <i className="zmdi zmdi-collection-text" />
                <span className="nav-text">Bulk Document</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/mediation/signature">
                <i className="zmdi zmdi-cloud-upload" />
                <span className="nav-text">Sign Digitally</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/mediation/update-cases">
                <i className="zmdi zmdi-cloud-upload" />
                <span className="nav-text">Update Cases</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/client/join-meeting">
                <i className="zmdi zmdi-videocam" />
                <span className="nav-text">Join Meeting</span>
              </NavLink>
            </li>
            {/* <li>
            <NavLink to="/app/mediation/users-approval/">
              <i className="zmdi zmdi-cloud-upload" />
              <span className="nav-text">User Approval</span>
            </NavLink>
          </li> */}

            {/* 

          <li>
            <NavLink to="/app/mediation/excel">
              <i className="zmdi zmdi-cloud-upload" />
              <span className="nav-text">Upload Shipments</span>
            </NavLink>
          </li>
               
          <li>
            <NavLink to="/app/mediation/update-cases">
              <i className="zmdi zmdi-cloud-upload" />
              <span className="nav-text">Update Cases</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/mediation/profile">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li> */}
          </ul>
        )
      ) : role == "Client" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/client/dashboard/">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/client/password-updae">
              <i className="zmdi zmdi-edit" />
              <span className="nav-text">Change Password</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Arbitration</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/all/cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">All Cases</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/clinet/single-case"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Single Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Mediation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/mediation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/mediation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/mediation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Conciliation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/clinet/conciliation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/conciliation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/conciliation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/client/booking">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/client/join-meeting">
              <i className="zmdi zmdi-videocam" />
              <span className="nav-text">Join Meeting</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/client/password-updae">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "collection" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/collection/dashboard/">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/collection/password-update">
              <i className="zmdi zmdi-edit" />
              <span className="nav-text">Change Password</span>
            </NavLink>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Arbitration</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/all/cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">All Cases</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/single-case"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Single Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Mediation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/client/mediation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/mediation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/mediation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Conciliation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/clinet/conciliation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/conciliation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/collection/conciliation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/collection/booking">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/collection/join-meeting">
              <i className="zmdi zmdi-videocam" />
              <span className="nav-text">Join Meeting</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/collection/password-update">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "casemanager" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/casemanager/dashboard">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Case Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/casemanager/client/list"
            >
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Clients List</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/casemanager/profile/add">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/casemanager/change-password/add">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Change Password </span>
            </NavLink>
          </li>
        </ul>
      ) : role == "lawfirm-manager" ? (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/lawfirm-manager/dashboard/">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Manager Dashboard</span>
            </NavLink>
          </li>


          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/manager/list"
            >
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Clients</span>
            </NavLink>
          </li>
          {/*
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Clients</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/manager/add"
                >

                  <span className="nav-text">Add Client </span>
                </NavLink>
              </li> 
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/manager/list"
                >

                  <span className="nav-text">Clients List</span>
                </NavLink>
              </li>

            </ul>
          </li>
*/}

          {/* <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/lawfirm-manager/client/list"
            >
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Clients List</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/lawfirm-manager/notification"
            >
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Notification</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/lawfirm-manager/profile/add">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/lawfirm-manager/change-password/add">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Change Password </span>
            </NavLink>
          </li>
        </ul>
      ) : (
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/party/dashboard">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/party/change-password">
              <i className="zmdi zmdi-edit" />
              <span className="nav-text">Change Password</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/party/join-meeting">
              <i className="zmdi zmdi-videocam" style={{ color: "#007eff" }} />
              <span className="nav-text" style={{ color: "#007eff" }}>
                Join Meeting
              </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Arbitration</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/register-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/registered-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/all-respondant-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Respondent Cases</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Mediation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/mediation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/mediation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/mediation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
              <span className="nav-text">Conciliation</span>
            </Button>
            <ul className="sub-menu">
              {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/conciliation/file/fresh-case"
                >
                  <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                  <span className="nav-text">File Fresh Case </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/conciliation/filed-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Filed Cases </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/party/conciliation/second-party-cases"
                >
                  {/* <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" /> */}
                  <span className="nav-text">Second Party Cases </span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li>
            <NavLink to="/app/lawfirm/signature">
              <i className="zmdi zmdi-border-color" />
              <span className="nav-text">Sign Digitally</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/app/party/bookings">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Bookings </span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/party/change-password">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Profile Settings </span>
            </NavLink>
          </li>
        </ul>
      )}
    </div>
  );
};
