import React from "react";
import axios from "axios";
import { API_URL, API_VERSION, API_TOKEN } from "../ApiUrl";

class Axios {
  defaultOptions = () => {
    return {
      baseURL: `${API_URL}${API_VERSION}`,
      headers: {
        Authorization: `${"Bearer"}${localStorage.getItem("token")}`,
        adminid: localStorage.getItem("_id"),
      },
    };
  };

  LogoutUser = (url) => {
    // console.log(url, 6666);
    if (url.response !== undefined && url.response.status === 401) {
      console.log(url.response.status, "401 Unauthorized Api Call");
      localStorage.clear();
      window.location.replace("/");
    } else {
      console.log(url, "401 Unauthorized Api Call");
    }
  };

  get = (url, options = {}) => {
    return axios
      .get(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data?.message?.includes("Not Authorized!")) {
          let err = {
            response: {
              status: 401,
            },
          };
          // this.LogoutUser(err);
        }
        return res;
      })
      .catch((err) => {
        if (err.response.status === 400) {
          // this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  Get = (url, options = {}) => {
    return axios
      .get(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.status !== 200) {
          let err = {
            response: {
              status: 400,
            },
          };
          // this.LogoutUser(err);
        }
        return res;
      })
      .catch((err) => {
        if (err.res.status === 400) {
          // this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  post = (url, data, options = {}) => {
    return axios
      .post(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {

        if (res.data?.success === false && res.data?.message) {
          for (const key in res.data.message) {
            if (res.data.message.hasOwnProperty(key)) {
              return Promise.reject({
                response: {
                  status: 400,
                  message: res.data.message[key][0],
                },
              });
            }
          }
        }
        // console.log(res,"res>>>>>>>>>")
        if (res.data?.message?.includes("Not Authorized!")) {
          let err = {
            response: {
              status: 401,
              // status: 500,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        console.log(err,"oiuoi")
        if (err.response.status === 401) {
          return { status: 401, data: err.response.data };
        } else {
          return err;
        }
      });
  };

  // dharmani
  Post = (url, data, options = {}) => {
    return axios
      .post(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        // console.log(res,"res>>>>>>>>>")
        if (res.status !== 200) {
          let err = {
            response: {
              status: 400,
              // status: 500,
            },
          };
          // this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        console.log(err, "oiuoioooooooooo");
        if (err.response) {
          if (err.response.status === 422) {
            console.log("Error 422:", err.response.data);
            return { status: 422, data: err.response.data };
          } else if (err.response.status === 401) {
            return { status: 401, data: err.response.data };
          }else if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          } else if (err.response.status === 400) {
            return { status: 400, data: err.response.data };
          } else if ( err.response.status === 404 ) {
            return { status: 404, data: err.response.data };
          }
        }
        return err; 
      });
   };

  put = (url, data, options = {}) => {
    return axios
      .put(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data?.message?.includes("Not Authorized")) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  delete = (url, options = {}) => {
    return axios
      .delete(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data?.message?.includes("Not Authorized")) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };
}

export default new Axios();
